


































































































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import PartnerComponent from "@/components/chat/PartnerComponent.vue";
import QuestionComponent from "@/components/chat/QuestionComponent.vue";
import Paginate from 'vuejs-paginate'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import removeMd from 'remove-markdown'
import markdownIt from "markdown-it"

require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    QuestionComponent,
    PartnerComponent,
    Paginate
  },
})
export default class OnayamiDetail extends Vue {
  isLoading = true
  rMd = removeMd
  mydata = false
  question: firebase.firestore.DocumentData = {title: '', uid: ''}
  questions: any[] = []
  questionFileUrls: any[] = []
  userInfo: any = {}
  rooms: any[] = []
  errorMessage = ''
  unsubscribe: any
  myuid = ''
  rates: any[] = []
  rateIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('rates')
  ratePage = 1
  ratePages = 1
  rateFilter = 'all'
  RATE_LOAD_COUNT = 5 //１ページの表示件数を定義

  @Watch('$route')
  changeId() {
    this.search()
  }

  mounted() {
    this.search()
  }

  md = new markdownIt({html: false, breaks: true})
  compiledMarkdownText() {
    return this.userInfo && this.userInfo.selfIntro ? this.md.render(this.userInfo.selfIntro) : ''
  }

  search() {
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.unsubscribe ? this.unsubscribe() : null
    this.questions = []
    this.questionFileUrls = []
    this.rooms = []
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).get().then((onayami) => {
      this.myuid = Common.getUserId()
      this.question = onayami.data() || this.question
      if (this.question.type != 'class') {
        this.question = {title: '', uid: ''}
        this.isLoading = false
        return
      }
      this.question.id = onayami.id
      if (this.question.files) {
        this.question.files.forEach((file: string) => {
          this.getDownloadUrl(file)
        })
      }
      this.isLoading = false
      this.mydata = this.question.uid == this.myuid

      if (this.mydata) {
        this.getUserInfo(this.question.uid)
        this.getRooms().then((rooms: any) => {
          rooms.forEach((room: any) => {
            let data = room.data()
            let obj = {id: room.id, onayamiId: room.ref.parent.parent!.id, status: data.status, chatNum: data.chatUid == this.question.uid ? 0 : data.chatNum, chat: data.chatText, date: (data.chatCreateTime ? data.chatCreateTime.toDate() : '')}
            this.rooms.push(obj)

            let partnerUid = data.uids[0] == this.myuid ? data.uids[1] : data.uids[0]
            firebase.firestore().collection('users').doc(partnerUid).get().then((user) => {
              let userData = user.data()!
              this.$set(obj, 'name', userData.name)
              this.$set(obj, 'url', Common.createUserIconURL(user.id, userData.fileExist))
            })
            firebase.firestore().collection('onayamis').doc(obj.onayamiId).get().then((onayami) => {
              this.$set(obj, 'title', onayami.data()!.title)
            })
          })
        })
      } else {
        this.getUserInfo(this.question.uid)
        if (this.question.tags.length != 0) {
          firebase.firestore().collection('onayamis').where('tags', 'array-contains', this.question.tags[0]).where('type', '==', 'class').orderBy('createTime', 'desc').limit(10).get().then((data) => {
            data.forEach((onayami) => {
              if (onayami.id != this.question.id) {
                let value = onayami.data()!
                let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, rate: value.rate, rateNum: value.rateNum, restSlot:value.peopleLimit - value.inClass, createTime: value.createTime.toDate(), uid: value.uid}
                obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
                obj.rateIcons = Common.createRateIcon(obj.rate)
                this.questions.push(obj)
              }
            })
          })
        }
      }
    }).catch((error) => {
      this.isLoading = false
    })
  }

  getDownloadUrl(file: string, retry = 1) {
    firebase.storage().ref(file).getDownloadURL().then((url) => {
      let fileNames = file.split('/')
      let type = fileNames[2] == 'files' ? 'files' : 'images'
      this.questionFileUrls.push({id: type == 'files' ? fileNames[3] : fileNames[2], url: url, type: type})
    }).catch(() => {
      if (retry <= 5) {
        setTimeout(() => {
          this.getDownloadUrl(file, retry + 1)
        }, 3000)
      }
    })
  }

  getUserInfo(uid: string) {
    firebase.firestore().collection('users').doc(uid).get().then((user) => {
      this.userInfo = user.data()!
      this.userInfo.uid = user.id
      this.userInfo.url = Common.createUserIconURL(user.id, this.userInfo.fileExist)
      this.userInfo.rateIcons = Common.createRateIcon(this.userInfo.clinicRate)
      this.userInfo.skills = Common.createViewSkill(this.userInfo.skillArray)
      this.rateSearch(this.userInfo)
    })
  }

  getRooms() {
    let uid = Common.getUserId()
    this.unsubscribe = firebase.firestore().collectionGroup('rooms').where('uids', 'array-contains', Common.getUserId()).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          let id = change.doc.id
          let data = change.doc.data()!
          let partnerUid = data.uids[0] == uid ? data.uids[1] : data.uids[0]
          for (let i=0; i<this.rooms.length; i++) {
            if (this.rooms[i].id == id) {
              this.rooms[i].chatText = data.chatText
              this.rooms[i].chatNum = data.chatUid == partnerUid ? data.chatNum : 0
              this.rooms[i].date = data.chatCreateTime ? moment(data.chatCreateTime.toDate()).format('MM/DD HH:mm') : ''
              break
            }
          }
        }
      })
    })

    return new Promise((resolve, reject) => {
      var roomCollection = firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').where('uids', 'array-contains', Common.getUserId()).where('chatStart', '==', true).orderBy('chatCreateTime', 'desc')
      roomCollection.get().then((rooms) => {
        resolve(rooms)
      }).catch((e) => {
        reject(e)
      })
    })
  }

  create() {
    // 非ログイン時はログイン画面に遷移
    if (Common.getUserId() == '') {
      this.$router.push('/login')
      return
    }
    this.isLoading = true
    let uid = Common.getUserId()
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').where('uids', 'array-contains', uid).where('patient', '==', uid).get().then((data) => {
      if (data.size == 0) {
        firebase.firestore().collection('onayamis').doc(this.$route.params.id).collection('rooms').doc(uid)
        .set({
          creator: uid,
          clinic: this.question.uid,
          patient: uid,
          uids: [uid, this.question.uid],
          chatNum: 0,
          clinicChatNum: 0,
          studentChatNum: 0,
          chatCreateTime: firebase.firestore.FieldValue.serverTimestamp(),
          status: 'before',
          hasReviewed: false,
          })
          .then((data) => {
          this.$router.push(`/onayamis/${this.$route.params.id}/chat/${uid}`)
        })
      } else {
        this.$router.push(`/onayamis/${this.$route.params.id}/chat/${data.docs[0].id}`)
      }
    })
  }

  select(question: any) {
    this.$router.push(`/onayami/${question.id}`)
  }

  selectRoom(room: firebase.firestore.DocumentData) {
    this.$router.push(`/onayamis/${this.$route.params.id}/chat/${room.id}`)
  }

  updateStart() {
    this.isLoading = true
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).update({finish: false, interrupt: false})
      .then(() => {
        this.question.finish = false
        this.question.interrupt = false
      }).finally(() => {
        this.isLoading = false
      })
  }

  updateInterrupt() {
    this.isLoading = true
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).update({finish: true, interrupt: true})
      .then(() => {
        this.question.finish = true
        this.question.interrupt = true
      }).finally(() => {
        this.isLoading = false
      })
  }

  updateFinish() {
    this.isLoading = true
    firebase.firestore().collection('onayamis').doc(this.$route.params.id).update({finish: true})
      .then(() => {
        this.question.finish = true
        this.question.interrupt = false
      }).finally(() => {
        this.isLoading = false
      })
  }

  deleteOnayami() {
    if (confirm('削除しますか？')) {
      this.isLoading = true
      firebase.firestore().collection('onayamis').doc(this.$route.params.id).delete()
      .then(() => {
        setTimeout(() => {
          this.$router.push('/onayami/list')
        }, 5000)
      }).catch(() => {
        this.errorMessage = '削除に失敗しました'
        this.isLoading = false
      })
    }
  }

  ratePageClick() {
    this.rateSearch(this.userInfo, true)
  }

  rateSearch(userInfo: any, paging = false) {
    if (!paging) {
      this.ratePage = 1
    }
    return this.rateIndex.search({
        query: userInfo.uid,
        filters: this.createFilterStr(),
        hitsPerPage: this.RATE_LOAD_COUNT,
        page: this.ratePage - 1
      }).then((responses: any) => {
        this.rates = []
        responses.hits.forEach((rate: any) => {
          rate.url = Common.createUserIconURL(rate.rater, true)
          Common.imageExist(rate.url).catch(() => rate.url = '')
          this.rates.push(rate)
        })
        this.ratePages = Math.ceil(responses.nbHits / this.RATE_LOAD_COUNT)
      })
  }

  createFilterStr() {
    let filter = ''
    if (this.rateFilter != 'all') {
      filter += 'rate=' + (this.rateFilter == 'good' ? 5 : (this.rateFilter == 'normal' ? 3 : 1))
    }
    filter += filter ? ' AND ' : ''
    filter += 'isClinic=1'
    filter += ` AND onayamiId:"${this.$route.params.id}"`
    return filter
  }

  beforeDestroy() {
    this.unsubscribe ? this.unsubscribe() : null
  }
}
